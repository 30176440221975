<template>
	<v-container
		v-if="hasPermission($route.meta.permission)"
		class="transparent"
		id="regular-tables"
		tag="section"
	>
		<v-card class="transparent ma-0" flat>
			<v-row class="ma-0"
				><v-col cols="12" class="px-0">
					<span class="text-h2">Orders</span>
				</v-col></v-row
			>
			<v-row class="ma-0 justify-center justify-md-start">
				<template v-if="hasPermission('Orders List')" class="d-flex red">
					<v-col cols="12" sm="5" md="3" class="px-0">
						<v-menu
							ref="delivery_date_menu"
							v-model="dateRangeMenue"
							:close-on-content-click="false"
							:return-value.sync="date"
							transition="scale-transition"
							min-width="auto"
							offset-y
							dense
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="formatDateFilter"
									label="Pick a date range"
									readonly
									range
									class="white"
									dense
									@click:append="resetDeliveryDateFilter"
									outlined
									:append-icon="clearDateRange"
									hide-details
									v-bind="attrs"
									v-on="on"
								>
									<template v-slot:prepend-inner>
										<v-icon>mdi-calendar</v-icon>
									</template>
									<!-- <template v-if=">
                  <v-icon >mdi-close</v-icon>
                </template>-->
								</v-text-field>
							</template>
							<v-date-picker
								class="ma-0"
								color="munjz-accent"
								v-model="deliveryDateFilter"
								range
								dense
							>
								<v-spacer></v-spacer>

								<v-btn text color="primary" @click="dateRangeMenue = false">
									OK
								</v-btn>
							</v-date-picker>
						</v-menu>
					</v-col>

					<v-col cols="6" sm="3" md="2">
						<v-autocomplete
							v-model="selectedOrderStates"
							:items="orderStateOptions"
							chips
							multiple
							dense
							class="white"
							outlined
							hide-details
							item-text="statusText"
							item-value="id"
							label="All Status"
						>
							<template v-slot:selection="data">
								<v-chip
									v-bind="data.attrs"
									:input-value="data.selected"
									close
									small
									outlined
									:color="data.item.color"
									dense
									@click="data.select"
									@click:close="
										removeFromSelcted(data.item, selectedOrderStates)
									"
									>{{ data.item.statusText }}</v-chip
								>
							</template>
						</v-autocomplete>
					</v-col>

					<v-col sm="4" md="3" class="me-auto">
						<v-autocomplete
							v-model="selectedSellers"
							:items="sellers"
							chips
							multiple
							dense
							class="white"
							text-color="white"
							outlined
							hide-details
							item-text="name"
							item-value="id"
							label="filter by seller"
						>
							<template v-slot:selection="data">
								<v-chip
									v-bind="data.attrs"
									:input-value="data.selected"
									close
									dense
									small
									@click="data.select"
									@click:close="removeFromSelcted(data.item, selectedSellers)"
									>{{ data.item.name }}</v-chip
								>
							</template>
						</v-autocomplete>
					</v-col>

					<v-col cols="6" sm="4" md="2">
						<v-text-field
							v-model="globalSearch"
							:append-icon="globalSearch ? 'mdi-close' : `mdi-magnify`"
							name="name"
							label="Search orders"
							dense
							@click:append="globalSearch != '' ? (globalSearch = '') : ''"
							class="white"
							outlined
							hide-details
						></v-text-field>
					</v-col>
				</template>
				<v-col
					cols="6"
					:sm="hasPermission('Orders List') ? '3' : '12'"
					:md="hasPermission('Orders List') ? '2' : '12'"
					class="d-flex justify-center"
				>
					<v-btn
						v-if="hasPermission('Order Creation')"
						dense
						:width="hasPermission('Orders List') ? '100%' : '20%'"
						class="ma-0 pa-0"
						color="primary"
						@click.stop="branchesDialog = true"
					>
						<v-icon left>mdi-plus</v-icon>New order
					</v-btn>
				</v-col>
			</v-row>

			<!-- Orders Table -->
			<v-row v-if="hasPermission('Orders List')">
				<v-col cols="12">
					<v-data-table
						dense
						:headers="headers"
						:items="orders"
						:server-items-length="totalOrders"
						:loading="loading"
						loading-text="Loading... Please wait"
						no-data-text="You haven't placed any orders yet"
						:options.sync="options"
						id="table"
						:footer-props="{
							itemsPerPageOptions: [20, 40, 60, 80, 100],
							prevIcon: 'mdi-arrow-collapse-left',
							nextIcon: 'mdi-arrow-collapse-right',
						}"
					>
						<template v-slot:item.order_no="{ item }">
							<a @click="viewOrder(item)" style="color: #1976d2">{{
								item.order_no
							}}</a>
						</template>

						<template v-slot:item.city="{ item }">
							<span>{{
								item.location
									? item.location
									: item.city
									? item.city.name_en
									: ""
							}}</span>
						</template>

						<template v-slot:item.status="{ item }">
							<v-chip
								small
								dark
								dense
								outlined
								:color="orderStateOptions[item.status].color"
								>{{ orderStateOptions[item.status].statusText }}</v-chip
							>
							<v-chip
								small
								v-if="item.approval_sent == 1"
								class="ma-2"
								dark
								style="background: green; color: #fff"
								color="#E9C510"
								>approval sent</v-chip
							>
						</template>

						<template v-slot:item.sp="{ item }">
							<a
								v-if="item.sp"
								style="color: #1976d2"
								@click="opensp(String(item.sp.id))"
								>{{ item.sp.name }}</a
							>
							<span v-else>N/A</span>
						</template>

						<template v-slot:item.area="{ item }">
							<span v-if="item.area">{{ item.area.name }}</span>
							<span v-else>Not confirmed</span>
						</template>

						<template v-slot:item.start_time="{ item }">
							<span v-if="item.start_time">{{ item.start_time }}</span>
							<span v-else>Not confirmed</span>
						</template>
						<template width="0" v-slot:item.customer_name="{ item }">
							<a style="color: #1976d2">
								{{ item.customer_name }}
								<br />
								<span>({{ item.customer_phone }})</span>
							</a>
						</template>

						<template width="0" v-slot:item.details="{ item }">
							{{ item.details[0] ? item.details[0].service.name : "" }}
							-
							{{ item.details[0] ? item.details[0].subservice.name : "" }}
						</template>

						<template v-slot:item.date="{ item }">
							<p>{{ item.status == 1 ? "High" : "Normal" }}</p>
						</template>

						<template v-slot:item.users_status="{ item }">
							<v-chip
								style="color: #fff"
								v-if="item.users_status == 0"
								class="ma-2"
								color="red"
								>Inactive</v-chip
							>
							<v-chip
								style="color: #fff"
								v-if="item.users_status == 1"
								class="ma-2"
								color="green"
								>Active</v-chip
							>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-card>
		<v-dialog
			v-model="branchesDialog"
			overlay-color="munjz-accent-2"
			overlay-opacity="0.7"
			persistent
			max-width="600px"
		>
			<v-card class="ma-0 rounded-lg">
				<v-container>
					<v-row class="ma-0">
						<v-col cols="9" class="me-auto d-flex align-center">
							<h2>Serlect a Branch</h2>
						</v-col>
						<v-col cols="2" sm="2" lg="1" class="pa-0">
							<div
								class="d-flex justify-end align-center"
								style="min-height: 100%"
							>
								<v-btn
									@click.stop="branchesDialog = false"
									class="blue-grey lighten-4 mx-0"
									small
									fab
									text
								>
									<v-icon large color="blue-grey ">mdi-close</v-icon>
								</v-btn>
							</div>
						</v-col>
						<v-col cols="12">
							<h4>choose the branch you want to place the order from</h4>
						</v-col>
						<v-col>
							<v-list dense class="rounded-lg blue-grey lighten-5">
								<v-list-item-group v-model="selectedBranch" color="primary">
									<v-list-item
										color="primary"
										v-for="(branch, idx) in branchesList"
										:key="idx"
										@click="createOrder(branch)"
									>
										<v-list-item-icon>
											<v-icon large>mdi-store-outline</v-icon>
										</v-list-item-icon>
										<v-list-item-icon>
											<v-icon small>mdi-map-marker-plus-outline</v-icon>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>{{ branch.name }}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
	</v-container>
	<v-container fluid v-else class="pa-10" id="regular-tables" tag="section">
		<v-row>
			<v-col cols="12" class="d-flex justify-center">
				<v-card flat>
					<!-- <v-card-title
						><v-card class="red">
							<h1 class="red pa-6">
								You Don't have Permission to view this page !
							</h1></v-card
						></v-card-title
					> -->
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
/* eslint-disable */

import moment from "moment";

export default {
	data() {
		return {
			selectedBranch: null,
			branchesList: [],
			branchesDialog: false,
			selectedOrderStates: [],
			dateRangeMenue: false,
			currentDate: null,
			date: null,
			deliveryDateFilter: [],
			user_id: localStorage.getItem("userid"),
			role: localStorage.getItem("role"),
			sellers: [],
			selectedSellers: [],
			moment: moment,
			token: localStorage.getItem("token"),
			parent_id: localStorage.getItem("parent_id"),
			deliveryDateFilterFromDialog: "",
			deliveryDateFilterToDialog: "",
			deliveryDateFilterTo: "",
			deliveryDateFilterFrom: "",
			totalOrders: 0,
			orders: [],
			options: {},
			loading: true,
			globalSearch: "",

			headers: [
				{
					text: "Order no.",
					align: "center",
					width: 30,
					value: "order_no",
				},
				{
					text: "Date",
					align: "center",
					width: 30,
					value: "delivery_date",
				},
				{
					text: "Seller",
					align: "center",
					width: 30,
					value: "order_seller.name",
				},
				{
					text: "Status",
					align: "center",
					width: 30,
					value: "status",
				},
				{
					text: "Branch",
					align: "center",
					width: 30,
					value: "branch.name",
				},
				{
					text: "Customer location",
					align: "center",
					width: 30,
					value: "city",
				},

				{
					text: "Services",
					align: "center",
					width: 30,
					value: "details",
				},
			],
			nameRules: [(v) => !!v || "Field is required"],

			orderStateOptions: [
				{ id: 0, statusText: "Pending", color: "#aeb400" },
				{
					id: 1,
					statusText: "Requested",
					color: "primary",
				},
				{
					id: 2,
					statusText: "Confirmed",
					color: "teal",
				},
				{ id: 3, statusText: "On the Way", color: "purple" },
				{
					id: 4,
					statusText: "In Progress",
					color: "orange",
				},
				{
					id: 5,
					statusText: "Completed",
					color: "green",
				},
				{
					id: 6,
					statusText: "Canceled",
					color: "red",
				},
			],
		};
	},
	components: {},
	computed: {
		formatDateFilter() {
			return this.deliveryDateFilter.length > 1
				? ` from ${this.deliveryDateFilter.join(" to ")}`
				: this.deliveryDateFilter.length > 0
				? `from ${this.deliveryDateFilter[0]}`
				: "Pick a date range";
		},
		clearDateRange() {
			return this.formatDateFilter.includes("fr") ? "mdi-close" : "";
		},
	},

	watch: {
		options: {
			handler() {
				this.getOrders();
			},
			deep: true,
		},
		globalSearch: {
			handler() {
				this.getOrders();
			},
			deep: true,
		},
		selectedSellers: {
			handler() {
				this.getOrders();
			},
			deep: true,
		},
		selectedOrderStates: {
			handler() {
				this.getOrders();
			},
			deep: true,
		},

		deliveryDateFilter: {
			handler() {
				this.getOrders();
			},
			deep: true,
		},
	},
	mounted() {
		this.getAdmins();
		this.getBranches();
	},
	created() {},
	methods: {
		createOrder(branch) {
			this.$store.dispatch(`getSelectedBranch`, branch);
			this.$router.push({ path: "create-order" });
		},
		getCurrentDate() {
			return new (Date(
				Date.now() - new Date().getTimezoneOffset() * 60000
			).toISOString)().substr(0, 10);
		},
		resetDeliveryDateFilter() {
			this.deliveryDateFilter = [];
		},

		viewOrder(item) {
			this.$router.push({
				path: `/order-details/${item.id}`,
			});
		},
		getOrders() {
			const { sortBy, sortDesc, page, itemsPerPage } = this.options;
			let payload = {};
			payload = {
				spa: 1,
				user_id: this.parent_id == 0 ? this.user_id : this.parent_id,
				page: page,
				globalsearch: this.globalSearch.replace(/\s+/g, ""),
				per_page: itemsPerPage,
				status: this.statuscode,
				sellers_ids: this.selectedSellers,
				status_ids: this.selectedOrderStates,
				from: this.deliveryDateFilter[0],
				to: this.deliveryDateFilter[1],
			};

			if (sortBy.length === 1 && sortDesc.length === 1) {
				if (sortDesc[0]) {
					const direction = "desc";
					payload.direction = direction;
					payload.sortBy = sortBy[0];
				} else {
					const direction = "asc";
					payload.direction = direction;
					payload.sortBy = sortBy[0];
				}
			}

			axios
				.get(`/api/v3/b2b/all-partners-order`, {
					params: payload,
				})
				.then((res) => {
					this.orders = res.data.data;
					this.totalOrders = res.data.total;
				})
				.catch((err) => console.log(err.response.data))
				.finally(() => (this.loading = false));

			// get by search
		},

		getAdmins() {
			//  alert("user"+this.user_id)
			axios
				.get("/api/v3/b2b/partner-admins", {
					headers: { "X-Authorization": this.token },
				})
				.then((res) => {
					this.sellers = res.data.partner_admins;
				})
				.catch((err) => console.log(err.response.data));
		},
		removeFromSelcted(item, array) {
			const index = array.indexOf(item.id);
			if (index > -1) array.splice(index, 1);
		},
		getBranches() {
			axios
				.get("/api/v3/b2b/partner-details", {
					headers: { "X-Authorization": this.token },
				})
				.then((res) => {
					this.branchesList = res.data.branches;
					this.$store.dispatch("setBranchesList", this.branchesList);
				})
				.catch((err) => console.log(err));
		},
	},
};
</script>
<style scoped>
/* .v-data-tab
/* .v-data-table table th {
  font-size: 16px !important;
  font-weight: bolder !important;
  color: #fff !important;
} */

/* .v-textarea textarea {
	font-size: 0.
/* .v-data-table table th {
  font-size: 16px !important;
  font-weight: bolder !important;
  color: #fff !important;
} */

/* .v-textarea textarea {
	font-size: 0.9em !important;
	max-height: 100px !important;
} */

/* label { */
/* font-size: 12px !important; */
/* font-weight: bold;
	color: grey;
} */
/* .v-text-field >>> input {
	font-size: 0.9em;
	font-size: 12px !important;
} */
